import type { BoxProps } from '@mui/material';
import { Box, Tooltip } from '@mui/material';
import StyledImage from './styledImage';

// function turnAllKeysToLowerCase() {
// 	const newList = {};
// 	for ( const key in CountryListWithCodes ) {
// 		newList[ key.toLowerCase() ] = CountryListWithCodes[ key ];
// 	}
// 	return newList;
// }

const CountryListWithCodes = {
	'afghanistan'                                               : 'AF',
	'albania'                                                   : 'AL',
	'algeria'                                                   : 'DZ',
	'american samoa'                                            : 'AS',
	'andorra'                                                   : 'AD',
	'angola'                                                    : 'AO',
	'anguilla'                                                  : 'AI',
	'antarctica'                                                : 'AQ',
	'antigua and barbuda'                                       : 'AG',
	'argentina'                                                 : 'AR',
	'armenia'                                                   : 'AM',
	'aruba'                                                     : 'AW',
	'australia'                                                 : 'AU',
	'austria'                                                   : 'AT',
	'azerbaijan'                                                : 'AZ',
	'bahamas (the)'                                             : 'BS',
	'bahrain'                                                   : 'BH',
	'bangladesh'                                                : 'BD',
	'barbados'                                                  : 'BB',
	'belarus'                                                   : 'BY',
	'belgium'                                                   : 'BE',
	'belize'                                                    : 'BZ',
	'benin'                                                     : 'BJ',
	'bermuda'                                                   : 'BM',
	'bhutan'                                                    : 'BT',
	'bolivia'                                                   : 'BO',
	'bonaire, sint eustatius and saba'                          : 'BQ',
	'bosnia and herzegovina'                                    : 'BA',
	'botswana'                                                  : 'BW',
	'bouvet island'                                             : 'BV',
	'brazil'                                                    : 'BR',
	'british indian ocean territory (the)'                      : 'IO',
	'brunei darussalam'                                         : 'BN',
	'bulgaria'                                                  : 'BG',
	'burkina faso'                                              : 'BF',
	'burundi'                                                   : 'BI',
	'cabo verde'                                                : 'CV',
	'cambodia'                                                  : 'KH',
	'cameroon'                                                  : 'CM',
	'canada'                                                    : 'CA',
	'ca'                                                        : 'CA',
	'cayman islands (the)'                                      : 'KY',
	'central african republic (the)'                            : 'CF',
	'chad'                                                      : 'TD',
	'chile'                                                     : 'CL',
	'china'                                                     : 'CN',
	'christmas island'                                          : 'CX',
	'cocos (keeling) islands (the)'                             : 'CC',
	'colombia'                                                  : 'CO',
	'comoros (the)'                                             : 'KM',
	'congo (the democratic republic of the)'                    : 'CD',
	'congo (the)'                                               : 'CG',
	'cook islands (the)'                                        : 'CK',
	'costa rica'                                                : 'CR',
	'croatia'                                                   : 'HR',
	'cuba'                                                      : 'CU',
	'curaçao'                                                   : 'CW',
	'cyprus'                                                    : 'CY',
	'czechia'                                                   : 'CZ',
	'côte d\'ivoire'                                            : 'CI',
	'denmark'                                                   : 'DK',
	'djibouti'                                                  : 'DJ',
	'dominica'                                                  : 'DM',
	'dominican republic (the)'                                  : 'DO',
	'ecuador'                                                   : 'EC',
	'egypt'                                                     : 'EG',
	'el salvador'                                               : 'SV',
	'equatorial guinea'                                         : 'GQ',
	'eritrea'                                                   : 'ER',
	'estonia'                                                   : 'EE',
	'eswatini'                                                  : 'SZ',
	'ethiopia'                                                  : 'ET',
	'falkland islands (the) [malvinas]'                         : 'FK',
	'faroe islands (the)'                                       : 'FO',
	'fiji'                                                      : 'FJ',
	'finland'                                                   : 'FI',
	'france'                                                    : 'FR',
	'french guiana'                                             : 'GF',
	'french polynesia'                                          : 'PF',
	'french southern territories (the)'                         : 'TF',
	'gabon'                                                     : 'GA',
	'gambia (the)'                                              : 'GM',
	'georgia'                                                   : 'GE',
	'germany'                                                   : 'DE',
	'ghana'                                                     : 'GH',
	'gibraltar'                                                 : 'GI',
	'greece'                                                    : 'GR',
	'greenland'                                                 : 'GL',
	'grenada'                                                   : 'GD',
	'guadeloupe'                                                : 'GP',
	'guam'                                                      : 'GU',
	'guatemala'                                                 : 'GT',
	'guernsey'                                                  : 'GG',
	'guinea'                                                    : 'GN',
	'guinea-bissau'                                             : 'GW',
	'guyana'                                                    : 'GY',
	'haiti'                                                     : 'HT',
	'heard island and mcdonald islands'                         : 'HM',
	'holy see (the)'                                            : 'VA',
	'honduras'                                                  : 'HN',
	'hong kong'                                                 : 'HK',
	'hungary'                                                   : 'HU',
	'iceland'                                                   : 'IS',
	'india'                                                     : 'IN',
	'indonesia'                                                 : 'ID',
	'iran (islamic republic of)'                                : 'IR',
	'iran'                                                      : 'IR',
	'iraq'                                                      : 'IQ',
	'ireland'                                                   : 'IE',
	'isle of man'                                               : 'IM',
	'israel'                                                    : 'IL',
	'italy'                                                     : 'IT',
	'jamaica'                                                   : 'JM',
	'japan'                                                     : 'JP',
	'jersey'                                                    : 'JE',
	'jordan'                                                    : 'JO',
	'kazakhstan'                                                : 'KZ',
	'kenya'                                                     : 'KE',
	'kiribati'                                                  : 'KI',
	'korea (the democratic people\'s republic of)'              : 'KP',
	'korea (the republic of)'                                   : 'KR',
	'kuwait'                                                    : 'KW',
	'kyrgyzstan'                                                : 'KG',
	'lao people\'s democratic republic (the)'                   : 'LA',
	'latvia'                                                    : 'LV',
	'lebanon'                                                   : 'LB',
	'lesotho'                                                   : 'LS',
	'liberia'                                                   : 'LR',
	'libya'                                                     : 'LY',
	'liechtenstein'                                             : 'LI',
	'lithuania'                                                 : 'LT',
	'luxembourg'                                                : 'LU',
	'macao'                                                     : 'MO',
	'madagascar'                                                : 'MG',
	'malawi'                                                    : 'MW',
	'malaysia'                                                  : 'MY',
	'maldives'                                                  : 'MV',
	'mali'                                                      : 'ML',
	'malta'                                                     : 'MT',
	'marshall islands (the)'                                    : 'MH',
	'martinique'                                                : 'MQ',
	'mauritania'                                                : 'MR',
	'mauritius'                                                 : 'MU',
	'mayotte'                                                   : 'YT',
	'mexico'                                                    : 'MX',
	'micronesia (federated states of)'                          : 'FM',
	'moldova (the republic of)'                                 : 'MD',
	'monaco'                                                    : 'MC',
	'mongolia'                                                  : 'MN',
	'montenegro'                                                : 'ME',
	'montserrat'                                                : 'MS',
	'morocco'                                                   : 'MA',
	'mozambique'                                                : 'MZ',
	'myanmar'                                                   : 'MM',
	'namibia'                                                   : 'NA',
	'nauru'                                                     : 'NR',
	'nepal'                                                     : 'NP',
	'netherlands (the)'                                         : 'NL',
	'new caledonia'                                             : 'NC',
	'new zealand'                                               : 'NZ',
	'nicaragua'                                                 : 'NI',
	'niger (the)'                                               : 'NE',
	'nigeria'                                                   : 'NG',
	'niue'                                                      : 'NU',
	'norfolk island'                                            : 'NF',
	'northern mariana islands (the)'                            : 'MP',
	'norway'                                                    : 'NO',
	'oman'                                                      : 'OM',
	'pakistan'                                                  : 'PK',
	'palau'                                                     : 'PW',
	'palestine, state of'                                       : 'PS',
	'panama'                                                    : 'PA',
	'papua new guinea'                                          : 'PG',
	'paraguay'                                                  : 'PY',
	'peru'                                                      : 'PE',
	'philippines (the)'                                         : 'PH',
	'pitcairn'                                                  : 'PN',
	'poland'                                                    : 'PL',
	'portugal'                                                  : 'PT',
	'puerto rico'                                               : 'PR',
	'qatar'                                                     : 'QA',
	'republic of north macedonia'                               : 'MK',
	'romania'                                                   : 'RO',
	'russian federation (the)'                                  : 'RU',
	'rwanda'                                                    : 'RW',
	'réunion'                                                   : 'RE',
	'saint barthélemy'                                          : 'BL',
	'saint helena, ascension and tristan da cunha'              : 'SH',
	'saint kitts and nevis'                                     : 'KN',
	'saint lucia'                                               : 'LC',
	'saint martin (french part)'                                : 'MF',
	'saint pierre and miquelon'                                 : 'PM',
	'saint vincent and the grenadines'                          : 'VC',
	'samoa'                                                     : 'WS',
	'san marino'                                                : 'SM',
	'sao tome and principe'                                     : 'ST',
	'saudi arabia'                                              : 'SA',
	'senegal'                                                   : 'SN',
	'serbia'                                                    : 'RS',
	'seychelles'                                                : 'SC',
	'sierra leone'                                              : 'SL',
	'singapore'                                                 : 'SG',
	'sint maarten (dutch part)'                                 : 'SX',
	'slovakia'                                                  : 'SK',
	'slovenia'                                                  : 'SI',
	'solomon islands'                                           : 'SB',
	'somalia'                                                   : 'SO',
	'south africa'                                              : 'ZA',
	'south georgia and the south sandwich islands'              : 'GS',
	'south sudan'                                               : 'SS',
	'spain'                                                     : 'ES',
	'sri lanka'                                                 : 'LK',
	'sudan (the)'                                               : 'SD',
	'suriname'                                                  : 'SR',
	'svalbard and jan mayen'                                    : 'SJ',
	'sweden'                                                    : 'SE',
	'switzerland'                                               : 'CH',
	'syrian arab republic'                                      : 'SY',
	'taiwan'                                                    : 'TW',
	'tajikistan'                                                : 'TJ',
	'tanzania, united republic of'                              : 'TZ',
	'thailand'                                                  : 'TH',
	'timor-leste'                                               : 'TL',
	'togo'                                                      : 'TG',
	'tokelau'                                                   : 'TK',
	'tonga'                                                     : 'TO',
	'trinidad and tobago'                                       : 'TT',
	'tunisia'                                                   : 'TN',
	'turkey'                                                    : 'TR',
	'turkmenistan'                                              : 'TM',
	'turks and caicos islands (the)'                            : 'TC',
	'tuvalu'                                                    : 'TV',
	'uganda'                                                    : 'UG',
	'ukraine'                                                   : 'UA',
	'united arab emirates (the)'                                : 'AE',
	'united kingdom of great britain and northern ireland (the)': 'GB',
	'united kingdom'                                            : 'GB',
	'england'                                                   : 'GB',
	'wales'                                                     : 'GB',
	'uk'                                                        : 'GB',
	'united states minor outlying islands (the)'                : 'UM',
	'united states'                                             : 'US',
	'united state'                                              : 'US',
	'us'                                                        : 'US',
	// 'sf'                                                        : 'US',
	'america'                                                   : 'US',
	'uruguay'                                                   : 'UY',
	'uzbekistan'                                                : 'UZ',
	'vanuatu'                                                   : 'VU',
	'venezuela (bolivarian republic of)'                        : 'VE',
	'viet nam'                                                  : 'VN',
	'virgin islands (british)'                                  : 'VG',
	'virgin islands (u.s.)'                                     : 'VI',
	'wallis and futuna'                                         : 'WF',
	'western sahara'                                            : 'EH',
	'yemen'                                                     : 'YE',
	'zambia'                                                    : 'ZM',
	'zimbabwe'                                                  : 'ZW',
	'åland islands'                                             : 'AX',
};

export default function CountryFlag( { country, ...props }: { country: string } & BoxProps ) {
	const countryCode = CountryListWithCodes[ country?.toLowerCase() ]?.toLowerCase();
	
	if ( !country || !countryCode ) return null;
	
	return (
		<Tooltip
			placement='right'
			title={country}>
			<Box display='block' {...props}>
				<StyledImage
					sx={{ mt: .5, borderRadius: '2px' }}
					src={`https://flagcdn.com/w20/${countryCode}.png`}
					alt={`${country} flag`}
				/>
			</Box>
		</Tooltip>
	);
}
